<template>
    <div class="flex flex-col">
        <QuillEditor
            v-model:content="value"
            theme="snow"
            toolbar="full"
            :content="value"
            :modules="modules"
            content-type="html"
            @text-change="updated" />
    </div>
</template>

<script setup lang="ts">
import { QuillEditor } from "@vueup/vue-quill"
import "@vueup/vue-quill/dist/vue-quill.snow.css"
import { onMounted, ref } from "vue"

const props = defineProps({
  "modelValue": null
})

const emit = defineEmits(["update:modelValue"])

let value = ref(props.modelValue)

const modules = [
    {
        name: "blotFormatter",
        module: QuillBlotFormatter.default,
    },
]

const updated = (event: any) => {
    emit("update:modelValue", value.value)
}

onMounted(() => {
    console.log(value)
})
</script>
